<template>
  <div id="app">
    <div>
      <nav class="navbar navbar-expand-md navbar-dark " style="background:#37517e">
      <a class="navbar-brand" href="/" style=" font-size: 20px ;">Niche Stock Simulator</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="container-fluid">
        <ul class="navbar-nav mr-auto" style=" font-size: 20px ;">
        <li class="nav-item mb-1">
          <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
          <!-- <router-link to="/home"   class="nav-link">Home</router-link> -->
        </li>
        
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <router-link v-if="currentUser" to="/portfolio" class="nav-link">Portfolio</router-link>
          
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <router-link v-if="currentUser" to="/dash" class="nav-link">Stock History</router-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <router-link v-if="currentUser" to="/buying" class="nav-link">Buy/Sell</router-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <router-link v-if="currentUser" to="/performance" class="nav-link">Performance</router-link>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
          <router-link v-if="currentUser" to="/learn" class="nav-link">Learn</router-link>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" v-if="currentUser" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Learn</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/learn">About Stock Market</a>
            <a class="dropdown-item" href="/list">List Stock Symbol</a>
            <a class="dropdown-item" href="/news">Stock News</a>
          </div>
        </li>
        <!-- <li class="nav-item">
          
          <router-link to="/symbol" class="nav-link">News</router-link>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
          <router-link v-if="currentUser" to="/" class="nav-link">Reading</router-link>
        </li> -->
      </ul>
      <ul v-if="!currentUser" class="navbar-nav ml-auto" style=" font-size: 20px ;">
          <!-- <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus" /> Sign Up
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" /> Login
            </router-link>
          </li>
      </ul>

      <ul v-if="currentUser" class="navbar-nav ml-auto" style=" font-size: 20px ;">
        <a class="nav-link" href="#">
          <li class="nav-item">
            Market is: 
            <span class="badge badge-success" v-if="marketStatus == 'OPEN'">{{ marketStatus }}</span>
            <span class="badge badge-danger" v-else>{{ marketStatus }}</span>
          </li>
        </a>
        <li class="nav-item">
          <router-link to="#" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.username }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </ul>
      </div>
    </div>
      </nav>
    </div>
   
    <!-- <div v-if="isOnline">Application is online!</div> -->
    <!-- <div v-else>Application is offline. No connection!</div>{{isOnline}} -->
 

    <div>
      <router-view />
    </div>
    <br>
    <br>
    <br>
    <div class="container" >
  <!-- Footer -->
  
  <footer
          class="text-center text-lg-start bg-dark text-white" 
          style="position: fixed;left: 0;bottom: 0;width: 100%;"
        
          >
    <!-- Grid container -->
    
    <!-- Grid container -->

    <!-- Copyright -->
    <div
         class="text-center p-3"
         style="background-color: #37517e"
         >
      © 2024: NicheStockSimulator
       
        <!-- <a class="text-white" href="#" data-toggle="modal" data-target="#exampleModalLong2">Privacy Policy</a -->
        
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      marketStatus: '',
      errorMessage: '',
      parsedObject: '',

      isOnline: navigator.onLine,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  mounted() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    this.handleConnectionChange();
  },
  beforeUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  },
  created() {
    this.fetchMarketStatus();
    this.localData();
  },
  methods: {
    handleConnectionChange() {
      this.isOnline = navigator.onLine;
    },
    localData(){
      var retrievedObject = localStorage.getItem('user');

      // CONVERT STRING TO REGULAR JS OBJECT
       this.parsedObject = JSON.parse(retrievedObject);
      // return parsedObject;
    },
    logOut() {
      let text = "You want to Logout!";
      if (confirm(text) == true) {
        this.$store.dispatch('auth/logout');
        this.$router.push('/home');
        // window.location.href = "http://localhost/PHP_CMS/"
      }
      
    },
    async fetchMarketStatus() {
      const apiKey = 'cof2o6pr01qj17o77srgcof2o6pr01qj17o77ss0'; // Replace with your actual API key
      const url = `https://finnhub.io/api/v1/stock/market-status?exchange=US&token=${apiKey}`;

      try {
        const response = await axios.get(url);
        // const today = new Date().toISOString().slice(0, 10);
        let status_market = response.data.isOpen
        if(status_market){
          this.marketStatus = 'OPEN'
        }else{
          this.marketStatus = 'CLOSE'
        }
        console.log(response.data.isOpen);
        
      } catch (error) {
        this.errorMessage = 'Error fetching market status.';
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
body{
  /* background-color: antiquewhite; */
 
}
</style>