import http from "../http-common.js";

class StockDataService {
  getAll(data) {
    return http.get("/portfolios",data);
  }

  get(id) {
    return http.get(`/portfolios/${id}`);
  }
  getGroup(id) {
    return http.get(`/portfolios/group/${id}`);
  }
  getUserCash(id) {
    return http.get(`/portfolios/cash/${id}`);
  }
  getUserBuy(id) {
    return http.get(`/portfolios/buy/${id}`);
  }
  getUserSell(id) {
    return http.get(`/portfolios/sell/${id}`);
  }
  getTotalShare(id) {
    return http.get(`/portfolios/share/${id}`);
  }
  getUserSellPerformance(id) {
    return http.get(`/portfolios/userSell/${id}`);
  }
  getSellQuan(id,symbol) {
    return http.get(`/portfolios/sellQuan/${id}/${symbol}`);
  }
  getBuyQuan(id,symbol) {
    return http.get(`/portfolios/buyQuan/${id}/${symbol}`);
  }
  create(data) {
    return http.post("/portfolios", data);
  }
  createSales(data) {
    return http.post("/portfolios/sell", data);
  }

  updateUserCash(id, data) {
    return http.put(`/portfolios/userCash/${id}`, data);
  }

//   delete(id) {
//     return http.delete(`/tutorials/${id}`);
//   }

//   deleteAll() {
//     return http.delete(`/tutorials`);
//   }

//   findByTitle(title) {
//     return http.get(`/tutorials?title=${title}`);
//   }
}

export default new StockDataService();