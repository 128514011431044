import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "@/components/LineChart.vue";
import Buying from "@/components/BuyPortal.vue";
import Portfolio from "@/components/Portfolio.vue";
const HomePage = () => import("@/components/HomePage.vue");
const StockSymbol = () => import("@/components/StockSymbol.vue")
const LoginPortal = () => import("@/components/LoginPortal.vue")
const Performance = () => import("@/components/UserPerformance.vue")
const LearnStock = () => import("@/components/LearnStock.vue")
const NewsPortal = () => import("@/components/NewsPortal.vue")
const routes = [
  {
    path: "/login",
    name: "LoginPortal",
    component: LoginPortal,
  },
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/list",
    name: "StockSymbol",
    component: StockSymbol,
  },
  {
    path: "/dash",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/performance",
    name: "Performance",
    component: Performance,
  },
  {
    path: "/buying",
    name: "Buy",
    component: Buying,
  },
  {
    path: "/learn",
    name: "Learn",
    component: LearnStock,
  },
  {
    path: "/news",
    name: "NewsPortal",
    component: NewsPortal,
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
router.beforeEach((to, from, next) => {
    const publicPages = ['/login','/','/symbol'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/home');
    } else {
      next();
    }
  });




export default router;