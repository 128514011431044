<template>
  <div class="container">
    <br/>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">Stock History</li>
      </ol>
    </nav>
     <user-info />
      <hr>
      <h3>Real Time Stock History</h3>
      <hr>
    <div class="chartAndButtonsContainer">
      <form
        action=""
        id="stockSymbolSearchForm"
        @submit.prevent="
          createStockPriceHistoryChartInSpecifiedDateHistoryRange(
            10000000000000000000000000
          )
        "
      >
        
        <!-- <div class="input-group mb-3" style="margin-top:20px">
          <input type="text"  name="stockSymbolSearch"  v-model="stockSymbol" class="form-control" placeholder="Enter the symbol of the stock you want to search" aria-label="Enter the symbol of the stock you want to search" aria-describedby="button-addon2">
          <button class="btn btn-dark" type="submit" id="button-addon2">Search</button>
        </div> -->
      </form>
      
      <!-- <h1>{{ stockSymbol }}</h1> -->
      <br />
      <div class="dateRangeButtons">
        <div class="btn-group" role="group" aria-label="Basic outlined example">
  

        <!-- <button
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(86400)
          "
        >
          1 day
        </button> -->
        <!-- <button
       type="button" class="btn btn-outline-dark"
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(604800)
          "
        >
          1 week
        </button>
        <button
        type="button" class="btn btn-outline-dark"
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(2630000)
          "
        >
          1 month
        </button>
        <button
        type="button" class="btn btn-outline-dark"
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(31536000)
          "
        >
          1 year
        </button>
        <button
        type="button" class="btn btn-outline-dark"
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(157680000)
          "
        >
          5 years
        </button>
        <button
        type="button" class="btn btn-outline-dark"
          @click="
            createStockPriceHistoryChartInSpecifiedDateHistoryRange(788400000)
          "
        >
          MAX
        </button> -->
        </div>
      </div>
      <!-- <div class="container">
        <canvas id="myChart" width="400px" height="170px"></canvas>
      </div> -->
      <div>
        <!-- <button type="button" style="display: block; margin: auto;" class="btn btn-outline-dark" @click="refreshPage">
          Refresh
        </button> -->
        <br/>
       <iframe title="advanced chart TradingView widget" lang="en" style="width:100%; height: 500px" id="tradingview_fb7c7" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="true" src="https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22NASDAQ%3AAAPL%22%2C%22frameElementId%22%3A%22tradingview_fb7c7%22%2C%22interval%22%3A%2230%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22details%22%3A%221%22%2C%22calendar%22%3A%221%22%2C%22hotlist%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Africa%2FAddis%20Ababa%22%2C%22withdateranges%22%3A%221%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22www.stockmarket.et%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22NASDAQ%3AAAPL%22%2C%22page-uri%22%3A%22www.stockmarket.et%2Fstock-market-data%2F%22%7D" ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  setup() {
    const refreshPage = () => {
      window.location.reload();
    }

    onMounted(() => {
      console.log('Component mounted');
    });

    return {
      refreshPage
    };
  }
}
// import { ref, computed } from "vue";
// import Chart from "chart.js/auto";
// import axios from "axios";
// import UserInfo from "./UserInfo.vue"
// let stockMarketHistory = [];
// let stockMarketHistoryDates = [];
// let stockMarketHistoryEpochDates = [];
// let stockMarketHistoryPrices = [];

// let myChart;
// myChart;

// export default {
//   components: {
//         UserInfo
//   },
//   name: "LineChart",
//   mounted() {
//     this.createStockPriceHistoryChartInSpecifiedDateHistoryRange(
//       23423423423423423423423423234
//     );
//     this.updateStockPriceHistoryChart();
//   },
//   setup() {
//     const refreshPage = () => {
//       window.location.reload();
//     }
//     let updateStockPriceHistoryChart = () => {
//       const ctx = document.getElementById("myChart");

//       const labels = stockMarketHistoryDates;
//       const data = {
//         labels: labels,
//         datasets: [
//           {
//             label: "Stock Market Price",
//             data: stockMarketHistoryPrices,
//             fill: false,
//             borderColor: "#4bc0c0",
//             tension: 0,
//             options: {
//               responsive: true,
//               maintainAspectRatio: false,
//             },
//           },
//         ],
//       };

//       const chartWithKey = Chart.getChart("myChart");
//       if (chartWithKey != undefined) {
//         chartWithKey.destroy();
//       }

//       myChart = new Chart(ctx, {
//         type: "line",
//         data: data,
//       });
//     };

//     let stockSymbol = ref("");
//     let AlphaVantageAPI_URL_LINK = computed(() => {
//       return (
//         "https://www.alphavantage.co/query?function=TIME_SERIES_WEEKLY_ADJUSTED&symbol=" +
//         stockSymbol.value +
//         "&apikey=B6WQJEGS292NUIA1"
//       );
//     });

//     console.log(AlphaVantageAPI_URL_LINK.value);

//     let createStockPriceHistoryChartInSpecifiedDateHistoryRange = (
//       dateRange
//     ) => {
//       // empty array if something already in there
//       if (stockMarketHistoryDates.length > 0) {
//         stockMarketHistoryDates = [];
//         stockMarketHistoryEpochDates = [];
//         stockMarketHistoryPrices = [];
//       }

//       axios
//         .get(AlphaVantageAPI_URL_LINK.value)
//         .then((response) => {
//           stockMarketHistory = response;

//           dateRange;

//           console.log(stockMarketHistory.data["Weekly Adjusted Time Series"]);

//           for (const property in stockMarketHistory.data[
//             "Weekly Adjusted Time Series"
//           ]) {
//             let closingPrice =
//               stockMarketHistory.data["Weekly Adjusted Time Series"][property][
//                 "4. close"
//               ];
//             let closingDateMonth = property.split("-")[1];
//             let closingDateYear = property.split("-")[0];
//             let closingDateDay = property.split("-")[2];
//             let closingDateProperlyFormatted = `${closingDateMonth}/${closingDateDay}/${closingDateYear}`;
//             let closingDateEpochTime =
//               Date.parse(closingDateProperlyFormatted) / 1000;

//             // console.log(closingDateEpochTime)

//             stockMarketHistoryDates.unshift(closingDateProperlyFormatted);
//             stockMarketHistoryEpochDates.unshift(closingDateEpochTime);
//             stockMarketHistoryPrices.unshift(closingPrice);
//           }
//         })
//         .then(() => {
//           let now = Date.now() / 1000;
//           let dateRangeDate = now - dateRange;

//           const isLaterDateThanDateRangeDate = (date) => {
//             return date > dateRangeDate;
//           };

//           let arrayIndexWhereDateIsLargerThanDateRangeDate =
//             stockMarketHistoryEpochDates.findIndex(
//               isLaterDateThanDateRangeDate
//             );

//           stockMarketHistoryDates.splice(
//             0,
//             arrayIndexWhereDateIsLargerThanDateRangeDate
//           );
//           stockMarketHistoryPrices.splice(
//             0,
//             arrayIndexWhereDateIsLargerThanDateRangeDate
//           );

//           updateStockPriceHistoryChart();
//         });
//     };

//     return {
//       stockSymbol,
//       refreshPage,
//       updateStockPriceHistoryChart,
//       createStockPriceHistoryChartInSpecifiedDateHistoryRange,
//     };
//   },
// };
</script>

<style scoped>
#chartContainer {
  width: 1000px;
  height: 300px;
  margin: 0 auto;
}
#stockSymbolSearchBar {
  margin-top: 30px;

  width: 30%;
}
#stockSymbolSearchForm .submit {
  padding: 10px 20px;
}
.dateRangeButtons button {
 
}
</style>
