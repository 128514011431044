<template>
  <div class="container-fluid">
    <br>
    <div class="row align-items-start">
        <div class="col">
            <div class="card text-white bg-primary text-center border-primary shadow">  <div class="card-body">
                <h5 class="card-title text-white">Cash Balance</h5>
                <!-- <p class="card-text">This is some content for the card. It can contain multiple paragraphs and descriptive text about the card's content.</p> -->
                <h2>{{Math.round(userCash * 100) / 100}} $</h2>
                <!-- <a href="#" class="btn btn-primary">Learn More</a> -->
            </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-white bg-primary text-center border-primary shadow"> 
                <!-- <div class="card-header"><h5 class="card-title text-white">Total Stock</h5></div>  -->
                <div class="card-body">
                    <h5 class="card-title text-white">Total Stock</h5> 
                    <h2> {{totalShare ? totalShare : 0}} </h2>
            </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card text-white bg-primary text-center border-primary shadow">  <div class="card-body">
                <h5 class="card-title text-white">Purchase Transaction</h5>
                <!-- <p class="card-text">This is some content for the card. It can contain multiple paragraphs and descriptive text about the card's content.</p> -->
                <h2>{{userBuy ? Math.round(userBuy * 100) / 100 : 0}} $</h2>
                <!-- <a href="#" class="btn btn-primary">Learn More</a> -->
            </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-white bg-primary text-center border-primary shadow">  <div class="card-body">
                <h5 class="card-title text-white">Sales Transaction</h5>
                <!-- <p class="card-text">This is some content for the card. It can contain multiple paragraphs and descriptive text about the card's content.</p> -->
                <h2>{{userSell ? Math.round(userSell * 100) / 100 : 0}} $</h2>
                <!-- <a href="#" class="btn btn-primary">Learn More</a> -->
            </div>
            </div>
        </div>
        
        
    </div>
  </div>
  <hr>
</template>

<script>
import StockDataService from "../services/StockDataService";
export default {
    data(){
        return {
            userCash: null,
            userBuy: null,
            userSell: null,
            parsedObject: null,
            totalShare: 0,
        }
    },
    methods: {
        localData(){
            var retrievedObject = localStorage.getItem('user');

            // CONVERT STRING TO REGULAR JS OBJECT
            this.parsedObject = JSON.parse(retrievedObject);
            // return parsedObject;
        },
        async loadUserCash(){
            try{
                await StockDataService.getUserCash(this.parsedObject.id)
                .then(response => {
                    console.log(response.data[0].cash);
                    this.userCash = response.data[0].cash    
                   
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
        async loadShare(){
            try{
                await StockDataService.getTotalShare(this.parsedObject.id)
                .then(response => {
                    console.log(response.data.length);
                    this.totalShare = response.data.length
                    // this.userCash = response.data[0].cash    
                   
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
        async loadUserBuy(){
            try{
                await StockDataService.getUserBuy(this.parsedObject.id)
                .then(response => {
                    console.log(response.data[0].price);
                    this.userBuy = response.data[0].price    
                   
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
        async loadUserSell(){
            try{
                await StockDataService.getUserSell(this.parsedObject.id)
                .then(response => {
                    console.log(response.data[0].price);
                    this.userSell = response.data[0].price    
                   
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
    },
    created(){
         this.localData();
         this.loadUserCash();
         this.loadUserBuy();
         this.loadUserSell();
         this.loadShare();
    }

}
</script>

<style>

</style>