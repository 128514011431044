<template>
  <div class="container">
    <br/>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">Portfolio</li>
      </ol>
    </nav>
    <user-info />
    
    <div>
        <div class="row" >
        <div class="col">
            <h3>Buy & Sell Summary</h3>
            <hr>
             <v-table
                height="300px"
                fixed-header
                theme="dark"
                >
                <thead>
                <tr>
                    
                    <th class="text-left">
                    #
                    </th>
                    <th class="text-left">
                    Stock
                    </th>
                    <th class="text-left">
                    Total Share
                    </th>
                    <th class="text-left">
                    Type
                    </th>
                    <th class="text-left">
                    Total Buying Price
                    </th>
                    
                </tr>
                </thead>
                <tbody>
                <tr  v-for="(portfolio, index) in groupPortfolios"
                    :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>{{portfolio.symbol}}</td>
                    <td>{{portfolio.quantity}}</td>
                    <td v-if="portfolio.type == 'Buy'"><span class="badge badge-info">{{portfolio.type}}</span></td>
                    <td v-else><span class="badge badge-primary">{{portfolio.type}}</span></td>
                    <td>{{Math.round(portfolio.price * 100) / 100}}</td>
                    <!-- <td>{{portfolio.current_price}}</td> -->
                    </tr>
                </tbody>
            </v-table>
        </div>
        
        <div class="col">
            <h3>Today Stocks Prices </h3>
            
            <hr>
            <v-table
                height="300px"
                fixed-header
                theme="dark"
                >
                <thead>
                <tr>
                    
                    <th class="text-left">
                    #
                    </th>
                    <th class="text-left">
                    Stock
                    </th>
                    <th class="text-left">
                    Current Price
                    </th>
                    <th class="text-left">
                    Change
                    </th>
                    <th class="text-left">
                    Open Price
                    </th>
                    <th class="text-left">
                    Day's Range
                    </th>
                    <th class="text-left">
                    High Price of Z Day
                    </th>
                    <th class="text-left">
                    Previous close price
                    </th>
                    
                </tr>
                </thead>
                <tbody>
                <tr v-for="(portfolio, index) in todayStockValue"
                    :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>{{portfolio.symbol}}</td>
                    <td>{{portfolio.c}}</td>
                    <td>{{portfolio.d}} 
                        <span v-if="portfolio.d > 0" style="color:green;font-size:22px">&#8593;</span>
                        <span v-else style="color:red;font-size:22px">&#8595;</span>
                    </td>
                    <td>{{portfolio.o}}</td>
                    <td>{{portfolio.l}}-{{portfolio.o}}</td>
                    <td>{{portfolio.h}}</td>
                    <td>{{portfolio.pc}}</td>
                </tr>
                </tbody>
            </v-table>
            
        </div>
        
    </div>
    <hr>
    <div class="row align-items-start">
       <div class="col">
            <h3>Portfolio History</h3>
            <!-- <table id="example" class="table table-hover table-striped">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Stock</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Buying Price</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Date</th>
                    <th scope="col">Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(portfolio, index) in portfolios"
                    :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>{{portfolio.symbol}}</td>
                    <td>{{portfolio.quantity}}</td>
                    <td>{{portfolio.price}}</td>
                    <td>{{portfolio.totalCost}}</td>
                    <td>{{portfolio.type}}</td>
                    <td>{{portfolio.createdAt}}</td>
                    </tr>
                
                </tbody>
            </table> -->
            <input type="text" v-model="searchTerm" class="form-control" placeholder="Search by Symbol/Type">
            <br/>
            <v-table
                height="300px"
                fixed-header
                theme="dark"
            >
                <thead>
                <tr>
                    <th class="text-left">
                    #
                    </th>
                    <th class="text-left">
                    Stock
                    </th>
                    <th class="text-left">
                    Share
                    </th>
                    <th class="text-left">
                    Buying Price
                    </th>
                    <th class="text-left">
                    Total Cost
                    </th>
                    <th class="text-left">
                    Type
                    </th>
                     <th class="text-left">
                    Date
                    </th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(portfolio, index) in filteredStocks"
                    :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>{{portfolio.symbol}}</td>
                    <td>{{portfolio.quantity}}</td>
                    <td>{{portfolio.price}}</td>
                    <td>{{portfolio.totalCost}}</td>
                    <td v-if="portfolio.type == 'Buy'"><span class="badge badge-info">{{portfolio.type}}</span></td>
                    <td v-else><span class="badge badge-primary">{{portfolio.type}}</span></td>
                    <td>{{portfolio.createdAt.split('T')[0]}}</td>
                    </tr>
                </tbody>
            </v-table>
        </div>
        <br>
    </div>
    </div>
    
  </div>
  
           
</template>

<script>
import axios from 'axios';
import StockDataService from "../services/StockDataService";
import UserInfo from "./UserInfo.vue"
export default {
    components: {
        UserInfo
    },
    data(){
        return {
            parsedObject: null,
            portfolios: [],
            groupPortfolios: [],
            currentStockPrice: [],
            todayStockValue: null,
            loading: true,

            searchTerm: ''
        }
    },
    created() {
        this.localData();
        this.loadPortfolio();
        this.loadGroupPortfolio();
    },
    computed: {
    filteredStocks() {
        return this.portfolios.filter(stock => {
            const searchTermLower = this.searchTerm.toLowerCase();
            return (
            stock.symbol.toLowerCase().includes(searchTermLower) ||
            stock.type.toLowerCase().includes(searchTermLower) ||
            stock.createdAt.split('T')[0].toLowerCase().includes(searchTermLower)
            );
        });
        },
    },
    methods: {
        localData(){
            var retrievedObject = localStorage.getItem('user');

            // CONVERT STRING TO REGULAR JS OBJECT
            this.parsedObject = JSON.parse(retrievedObject);
            // return parsedObject;
        },
        pushUniqueProperty(arr, key, value, newValue, newValueKey) {
            // Check if the array is empty or undefined
            if (!arr) {
                return;
            }

            const existingObject = arr.find(obj => obj[key] === value);

            // If the object with the matching key and value exists, update it
            if (existingObject) {
                existingObject[newValueKey] = newValue;
            } else {
                // If the object doesn't exist, create a new one with the key, value, and the new property
                arr.push({ [key]: value, [newValueKey]: newValue });
            }
        },
        async loadPortfolio(){
            try{
                await StockDataService.get(this.parsedObject.id)
                .then(response => {
                    this.portfolios = response.data    
                    // console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
        async getStockInfo(symbol) {
            const apiKey = 'cof2o6pr01qj17o77srgcof2o6pr01qj17o77ss0'; // Replace with your actual Finnhub API key
            const baseUrl = 'https://finnhub.io/api/v1/quote?symbol=';

           await axios.get(`${baseUrl}${symbol}&token=${apiKey}`)
                .then(response => {
                    var res = response.data
                    // var obj = JSON.parse(res)
                    res.symbol = symbol
                    this.currentStockPrice.push(res)
                    this.todayStockValue = JSON.parse(JSON.stringify(this.currentStockPrice))
                    console.log(JSON.parse(JSON.stringify(this.currentStockPrice)))
                })
                .catch(error => {
                console.error('Error fetching stock info:', error);
                return false;
                // Handle errors here, like displaying an error message to the user
                });
                
        },
        async loadGroupPortfolio(){
            try{
                await StockDataService.getGroup(this.parsedObject.id)
                .then(response => {
                    this.groupPortfolios = response.data 
                     
                    var symbolList = Array.from(new Set(response.data.map(obj => obj['symbol']) ))  
                    
                   
                    for(let i=0;i<symbolList.length; i++){
                        this.getStockInfo(symbolList[i])
                    }
                    this.loading = false
                   
                    
                    
                })
                .catch(e => {
                    console.log(e);
                });
                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
        },
    }
}
</script>

<style>

</style>