<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">Stock Buy/Sell</li>
      </ol>
    </nav>
    <user-info />
    
    <div class="input-group mb-3" style="margin-top:20px">
      <input type="text"  name="stockSymbolSearch"  v-model="stockSymbol" class="form-control" placeholder="Enter Stock Symbol" aria-label="Enter Stock Symbol" aria-describedby="button-addon2">
      <button class="btn btn-dark" type="button"  @click="getStockInfo" id="button-addon2">Search</button>
    </div>
    <div v-show="loading" >
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    
  <div class="row align-items-start">
    <div class="col">
      <div v-if="companyData">
      <!-- <h1>Company Profile</h1> -->
      <div   class="card mb-3">
        <img :src="companyData.logo" style="height: 30%;" class="card-img-top" alt="{{companyData.name}}">
        <div class="card-body">
          <h5 class="card-title">{{ companyData.name }} ({{ companyData.ticker }})</h5>
          <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Country:</strong> {{ companyData.country }}</li>
          <li class="list-group-item"><strong>Industry:</strong> {{ companyData.finnhubIndustry }}</li>
          <li class="list-group-item"><strong>Website:</strong> <a :href="companyData.weburl">{{ companyData.weburl }}</a></li>
        </ul>
      </div>
      </div>
    </div>
    <div class="col">
      <div v-if="companyInfo">
        <ul  class="list-group">
        <button type="button" class="list-group-item list-group-item-action active" style="background-color:black" aria-current="true">
          {{ companyInfo.longName }} ({{ stockSymbol }})
        </button>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Current Price:
          <span class="badge badge-dark badge-pill">{{ currentPrice }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Change:
          <span class="badge badge-dark badge-pill">{{ changeAmount }} ({{ changePercent }}%)</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Open Price:
          <span class="badge badge-dark badge-pill">{{ openPrice }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Day's Range:
          <span class="badge badge-dark badge-pill">{{ dayRangeLow }} - {{ dayRangeHigh }}</span>
        </li>
        </ul>
       
        <div class="alert alert-info" role="alert">
          <h3>Your Stock Info!</h3>
          
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Total Share: 
              <span class="badge badge-info badge-pill">{{buyQuantity ? buyQuantity : 0}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Total Cost: 
              <span class="badge badge-info badge-pill">{{buyCost ? buyCost : 0}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Average Cost per Share: 
              <span class="badge badge-info badge-pill">{{(buyCost/buyQuantity).toFixed(2) ? (buyCost/buyQuantity).toFixed(2) : 0}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Total Sales:
              <span class="badge badge-info badge-pill">{{sellQuantity? sellQuantity : 0}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
             Stock Left:
              <span class="badge badge-info badge-pill">{{buyQuantity - sellQuantity? buyQuantity - sellQuantity : 0 }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
             Stock Value:
              <span class="badge badge-info badge-pill">{{((buyQuantity - sellQuantity)*buyCost/buyQuantity).toFixed(2)? ((buyQuantity - sellQuantity)*buyCost/buyQuantity).toFixed(2) : 0 }}</span>
            </li>
          </ul>
           <div class="input-group mb-3" style="margin-top:10px">
            <input type="number" class="form-control" v-model="amount" placeholder="Enter Amount You Want to Buy" aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-info" @click="buyStock" type="button">Buy Stock</button>
            </div>
          </div>
          <div class="input-group mb-3" style="margin-top:10px">
            <input type="number" class="form-control" v-model="salesAmount"  placeholder="Enter Amount You Want to Buy" aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-success" @click="sellStock" type="button">Sell Stock</button>
            </div>
           
          </div>
          <div class="alert alert-primary" role="alert" v-if="salesAmount">
             <p >The Profit of selling {{ salesAmount }} share is: {{ calculateProfit }}</p>
          </div>
        </div>
        
      </div>
      <p v-else></p>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import StockDataService from "../services/StockDataService";
import UserInfo from "./UserInfo.vue"
export default {
    components: {
        UserInfo
    },
  data() {
    return {
      cash: null,
      stockSymbol: '',
      companyInfo: null,
      currentPrice: null,
      changeAmount: null,
      changePercent: null,
      openPrice: null,
      dayRangeLow: null,
      dayRangeHigh: null,
      companyData: null,

      amount: 0,
      salesAmount: 0,
      parsedObject: null,
      messageLog: null,
      sellQuantity: null,
      // sellCost: null,
      buyQuantity: null,
      buyCost: null,
      profit: 0,

      loading: false
      
    };
  },
  computed: {
    calculateProfit(){
      // return (this.currentPrice * this.salesAmount)  - (this.buyCost/this.buyQuantity * this.salesAmount)
      var sellCal = this.currentPrice * this.salesAmount
      var buyCal = this.buyCost/this.buyQuantity *  this.salesAmount
      var profit = sellCal - buyCal
      return profit 
    }
  },
  created() {
    this.localData();
    this.loadUserCash();
  },
  methods: {
    localData(){
      var retrievedObject = localStorage.getItem('user');

      // CONVERT STRING TO REGULAR JS OBJECT
       this.parsedObject = JSON.parse(retrievedObject);
      // return parsedObject;
    },
    async loadUserCash(){
            try{
                await StockDataService.getUserCash(this.parsedObject.id)
                .then(response => {
                    // console.log(response.data[0].cash);
                    this.cash = response.data[0].cash    
                   
                })
                .catch(e => {
                    console.log(e);
                });

                
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
    },
    async sellQuan(){
            try{
                await StockDataService.getSellQuan(this.parsedObject.id,this.companyData.ticker)
                .then(response => {
                    this.sellQuantity = response.data[0].quantity;  
                   console.log(response.data[0].quantity);
                })
                .catch(e => {
                    console.log(e);
                });
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
    },
    async buyQuan(){
            try{
                await StockDataService.getBuyQuan(this.parsedObject.id,this.companyData.ticker)
                .then(response => {
                    this.buyQuantity = response.data[0].quantity;  
                    
                   console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
            } catch (error) {
                // console.error('Error fetching stock quote:', error);
                alert('An error occurred. Please try again later.');
            }
    },
    async buyStock(){
      if (this.amount <= 0) {
        alert('Please enter a valid quantity.');
        return;
      }
      let text = "Do You Want to Buy This Stock!";
      if (confirm(text) == true) {
        try{
          const stockPrice = this.currentPrice;
          const totalCost = stockPrice * this.amount;
          if (totalCost > this.cash) {
              alert('Insufficient Cash for this purchase.');
              return;
          }
          this.cash -= totalCost;
          var updateCash = {
            cash: this.cash
          }
          await StockDataService.updateUserCash(this.parsedObject.id,updateCash)
          .then(res =>{
            console.log(res);
          })
          var data = {
            userId: this.parsedObject.id,
            symbol: this.companyData.ticker,
            quantity: this.amount,
            price: stockPrice,
          };
          await StockDataService.create(data)
          .then(response => {
            // this.tutorial.id = response.data.id;
            console.log(response.data);
            location.reload(); 

            alert('Stock Purchased!')
            
          })
          .catch(e => {
            console.log(e);
          });

          this.amount = 0;
        } catch (error) {
          // console.error('Error fetching stock quote:', error);
          alert('An error occurred. Please try again later.');
        }
      }
    },
    async sellStock(){
      if (this.salesAmount <= 0) {
        alert('Please enter a valid quantity.');
        return;
      }
      let text = "Do You Want to Sell This Stock!";
      if (confirm(text) == true) {
        try{
          this.sellQuan()
          this.buyQuan();
          var stockLeft = this.buyQuantity - this.sellQuantity
          if (this.salesAmount > stockLeft) {
              alert('You dont have this much stock to sell.');
              return;
          }
          const stockPrice = this.currentPrice;
          const totalCost = stockPrice * this.salesAmount;

          var sellCal = stockPrice * this.salesAmount
          var buyCal = this.buyCost/this.buyQuantity *  this.salesAmount
          this.profit = sellCal - buyCal

          var data = {
            userId: this.parsedObject.id,
            symbol: this.companyData.ticker,
            quantity: this.salesAmount,
            profit: this.profit,
            price: stockPrice,
          };
          console.log(data);
          
          this.cash = eval(this.cash) + eval(totalCost);
          var updateCash = {
            cash: this.cash.toFixed(2)
          } 
          await StockDataService.createSales(data)
          .then(response => {
            console.log(response.data.message);
            this.messageLog = response.data.message
            alert(response.data.message)
            // location.reload(); 
            
            
          })
          .catch(e => {
            console.log(e);
          });

          if(this.messageLog == 'Sales created successfully!'){
            await StockDataService.updateUserCash(this.parsedObject.id,updateCash)
            .then(res =>{
              console.log(res);
              this.salesAmount = 0;
              location.reload()
            })
          }

          
        } catch (error) {
          // console.error('Error fetching stock quote:', error);
          alert('An error occurred. Please try again later.');
        }
      }
    },
    async getStockInfo() {
      this.loading = true
      const apiKey = 'cof2o6pr01qj17o77srgcof2o6pr01qj17o77ss0'; // Replace with your actual Finnhub API key
      const baseUrl = 'https://finnhub.io/api/v1/quote?symbol=';

     await axios.get(`${baseUrl}${this.stockSymbol}&token=${apiKey}`)
        .then(response => {
          this.companyInfo = response.data;
          this.currentPrice = this.companyInfo.c;
          this.changeAmount = this.companyInfo.d;
          this.changePercent = this.companyInfo.dp;
          this.openPrice = this.companyInfo.o;
          this.dayRangeLow = this.companyInfo.l;
          this.dayRangeHigh = this.companyInfo.h;
          
        })
       await StockDataService.getSellQuan(this.parsedObject.id,this.stockSymbol)
                .then(response => {
                    this.sellQuantity = response.data[0].quantity;  
                   console.log(response.data[0].quantity);
                })
         await StockDataService.getBuyQuan(this.parsedObject.id,this.stockSymbol)
                .then(response => {
                    this.buyQuantity = response.data[0].quantity; 
                    this.buyCost = response.data[0].price; 
                   console.log(response.data[0].quantity);
                    this.loading = false
                }) 
                      
        .catch(error => {
          console.error('Error fetching stock info:', error);
          // Handle errors here, like displaying an error message to the user
        });
        this.fetchCompanyProfile()
    },
    async fetchCompanyProfile() {
      const apiKey1 = 'cof2o6pr01qj17o77srgcof2o6pr01qj17o77ss0';
      const url = `https://finnhub.io/api/v1/stock/profile2?symbol=${this.stockSymbol}&token=${apiKey1}`;
       try {
        const response = await axios.get(url);
        // console.log(response);
        // console.log(response.data);
        this.companyData = response.data;
      } catch (error) {
        console.error('Error fetching company profile:', error);
      }
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  padding: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  font-size: 16px;
}

.search-bar button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
